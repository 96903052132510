import { createGlobalStyle } from "styled-components"
import styled from "styled-components"

export const Main = styled.main`
  /* margin-top: 56px; */
  /* overflow-x: hidden; */
  @media (min-width: 600px) {
    /* margin-top: 72px; */
}
`

export const GlobalStyle = createGlobalStyle`

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
   
  }

// :root {

// }
html {
    font-size: 16px;
}
body {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: acumin-pro, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    color: ${({ theme }) => theme.textColor};
    letter-spacing: 1.1px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
    
}

li {
    margin: 0;
    font-size: 0.9rem;
}

h1, h2, h3, h4, h5 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}

button {
    padding: 0;
    outline: none;
}

p {
    margin: 0;
}

select {
  width: 100%;
  height: 40px;
  -moz-padding-start: calc(10px - 3px);
  padding-left: 10px;
  color: black;
  font-size: 16px;
  margin-top: 0.5rem;
  outline: none;
}

input {
    outline: none;
}
`
