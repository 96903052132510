/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */


// You can delete this file if you're not using it

import React from 'react';
import RootLayout from "./src/components/RootLayout";

export const wrapRootElement = ({ element }) => <RootLayout>{element}</RootLayout>;

// export const wrapPageElement = ({ element, ...restProps }, ...args) => {
//     return (
//       // <Layout name="wrapPageElement" props={{}} args={args} mode="browser">
//       <Layout
//         name="wrapPageElement"
//         props={restProps}
//         args={args}
//         mode="browser"
//       >
//         {element}
//       </Layout>
//     );
//   };