import React from 'react'
import { Helmet } from "react-helmet";
import { GlobalStyle } from "../styles/GlobalStyle"
import { ThemeProvider } from 'styled-components';
import { theme } from "../styles/Theme"
import FavContextProvider from "../context/FavContext";

export default function RootLayout({ children }) {
    return (
        <>
            <Helmet>
            <link  href="https://use.typekit.net/upq7duy.css" rel="stylesheet" type="text/css" />
            {/* <script src="https://cal.bookingflix.com/vendor/embed.js" key= "eyJkYXRhIjp7ImxvYWQiOnsidXNlciI6ImY3Y2FkZTgwYjdjYzkyYjk5MWNmNGQyODA2ZDZiZDc4IiwiSUQiOiIxNjE0ODUwNDY2IiwiZGVtbyI6ZmFsc2UsImxhbmciOiJkZSJ9fSwic2F2ZVNldHRpbmdzIjoiaHR0cHM6Ly9jYWwuYm9va2luZ2ZsaXguY29tL3ZlbmRvci9zYXZlL2RlLmpzIn0="></script> */}
            </Helmet>
            <ThemeProvider theme={theme}>
            <GlobalStyle />
            <FavContextProvider>
                {children}
            </FavContextProvider>
            </ThemeProvider>
        </>
    );
}