// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessoires-dekoration-jsx": () => import("./../../../src/pages/accessoires/dekoration.jsx" /* webpackChunkName: "component---src-pages-accessoires-dekoration-jsx" */),
  "component---src-pages-accessoires-leuchten-jsx": () => import("./../../../src/pages/accessoires/leuchten.jsx" /* webpackChunkName: "component---src-pages-accessoires-leuchten-jsx" */),
  "component---src-pages-accessoires-teppiche-jsx": () => import("./../../../src/pages/accessoires/teppiche.jsx" /* webpackChunkName: "component---src-pages-accessoires-teppiche-jsx" */),
  "component---src-pages-allgemeine-geschaeftsbedingungen-js": () => import("./../../../src/pages/allgemeine-geschaeftsbedingungen.js" /* webpackChunkName: "component---src-pages-allgemeine-geschaeftsbedingungen-js" */),
  "component---src-pages-aufbewahrung-garderoben-jsx": () => import("./../../../src/pages/aufbewahrung/garderoben.jsx" /* webpackChunkName: "component---src-pages-aufbewahrung-garderoben-jsx" */),
  "component---src-pages-aufbewahrung-jsx": () => import("./../../../src/pages/aufbewahrung.jsx" /* webpackChunkName: "component---src-pages-aufbewahrung-jsx" */),
  "component---src-pages-aufbewahrung-regale-jsx": () => import("./../../../src/pages/aufbewahrung/regale.jsx" /* webpackChunkName: "component---src-pages-aufbewahrung-regale-jsx" */),
  "component---src-pages-aufbewahrung-schraenke-jsx": () => import("./../../../src/pages/aufbewahrung/schraenke.jsx" /* webpackChunkName: "component---src-pages-aufbewahrung-schraenke-jsx" */),
  "component---src-pages-aufbewahrung-sideboards-jsx": () => import("./../../../src/pages/aufbewahrung/sideboards.jsx" /* webpackChunkName: "component---src-pages-aufbewahrung-sideboards-jsx" */),
  "component---src-pages-baenke-js": () => import("./../../../src/pages/baenke.js" /* webpackChunkName: "component---src-pages-baenke-js" */),
  "component---src-pages-betten-js": () => import("./../../../src/pages/betten.js" /* webpackChunkName: "component---src-pages-betten-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-einrichtungshaus-jsx": () => import("./../../../src/pages/einrichtungshaus.jsx" /* webpackChunkName: "component---src-pages-einrichtungshaus-jsx" */),
  "component---src-pages-einrichtungsplanung-js": () => import("./../../../src/pages/einrichtungsplanung.js" /* webpackChunkName: "component---src-pages-einrichtungsplanung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-pages-sofas-1-5-2-3-sitzer-sofas-js": () => import("./../../../src/pages/sofas/1_5-2-3-sitzer-sofas.js" /* webpackChunkName: "component---src-pages-sofas-1-5-2-3-sitzer-sofas-js" */),
  "component---src-pages-sofas-jsx": () => import("./../../../src/pages/sofas.jsx" /* webpackChunkName: "component---src-pages-sofas-jsx" */),
  "component---src-pages-sofas-schlafsofas-js": () => import("./../../../src/pages/sofas/schlafsofas.js" /* webpackChunkName: "component---src-pages-sofas-schlafsofas-js" */),
  "component---src-pages-sofas-sessel-js": () => import("./../../../src/pages/sofas/sessel.js" /* webpackChunkName: "component---src-pages-sofas-sessel-js" */),
  "component---src-pages-sofas-sofas-mit-recamiere-und-ecksofas-js": () => import("./../../../src/pages/sofas/sofas-mit-recamiere-und-ecksofas.js" /* webpackChunkName: "component---src-pages-sofas-sofas-mit-recamiere-und-ecksofas-js" */),
  "component---src-pages-stuehle-js": () => import("./../../../src/pages/stuehle.js" /* webpackChunkName: "component---src-pages-stuehle-js" */),
  "component---src-pages-termin-vereinbaren-js": () => import("./../../../src/pages/termin-vereinbaren.js" /* webpackChunkName: "component---src-pages-termin-vereinbaren-js" */),
  "component---src-pages-tische-couch-und-beistelltische-jsx": () => import("./../../../src/pages/tische/couch-und-beistelltische.jsx" /* webpackChunkName: "component---src-pages-tische-couch-und-beistelltische-jsx" */),
  "component---src-pages-tische-esstische-js": () => import("./../../../src/pages/tische/esstische.js" /* webpackChunkName: "component---src-pages-tische-esstische-js" */),
  "component---src-pages-tische-jsx": () => import("./../../../src/pages/tische.jsx" /* webpackChunkName: "component---src-pages-tische-jsx" */),
  "component---src-pages-tische-schreibtische-jsx": () => import("./../../../src/pages/tische/schreibtische.jsx" /* webpackChunkName: "component---src-pages-tische-schreibtische-jsx" */),
  "component---src-pages-ueber-diewohnplaner-js": () => import("./../../../src/pages/ueber-diewohnplaner.js" /* webpackChunkName: "component---src-pages-ueber-diewohnplaner-js" */),
  "component---src-pages-wunschliste-js": () => import("./../../../src/pages/wunschliste.js" /* webpackChunkName: "component---src-pages-wunschliste-js" */),
  "component---src-templates-baenke-js": () => import("./../../../src/templates/Baenke.js" /* webpackChunkName: "component---src-templates-baenke-js" */),
  "component---src-templates-betten-js": () => import("./../../../src/templates/Betten.js" /* webpackChunkName: "component---src-templates-betten-js" */),
  "component---src-templates-garderoben-js": () => import("./../../../src/templates/Garderoben.js" /* webpackChunkName: "component---src-templates-garderoben-js" */),
  "component---src-templates-leuchten-js": () => import("./../../../src/templates/Leuchten.js" /* webpackChunkName: "component---src-templates-leuchten-js" */),
  "component---src-templates-regale-js": () => import("./../../../src/templates/Regale.js" /* webpackChunkName: "component---src-templates-regale-js" */),
  "component---src-templates-schraenke-js": () => import("./../../../src/templates/Schraenke.js" /* webpackChunkName: "component---src-templates-schraenke-js" */),
  "component---src-templates-sessel-js": () => import("./../../../src/templates/Sessel.js" /* webpackChunkName: "component---src-templates-sessel-js" */),
  "component---src-templates-sideboards-js": () => import("./../../../src/templates/Sideboards.js" /* webpackChunkName: "component---src-templates-sideboards-js" */),
  "component---src-templates-sofas-js": () => import("./../../../src/templates/Sofas.js" /* webpackChunkName: "component---src-templates-sofas-js" */),
  "component---src-templates-stuehle-js": () => import("./../../../src/templates/Stuehle.js" /* webpackChunkName: "component---src-templates-stuehle-js" */),
  "component---src-templates-teppiche-js": () => import("./../../../src/templates/Teppiche.js" /* webpackChunkName: "component---src-templates-teppiche-js" */),
  "component---src-templates-tische-js": () => import("./../../../src/templates/Tische.js" /* webpackChunkName: "component---src-templates-tische-js" */)
}

