import React, { createContext, useState, useEffect } from 'react';


export const FavContext = createContext();

const FavContextProvider = (props) => {

    const [added, setAdded] = useState(false)
    const [favs, setFavs] = useState([])

    useEffect(() => {
        const localData = localStorage.getItem('favs');
        setFavs(() => {
        return localData ? JSON.parse(localData) : [];
    })
    }, [])

    // const [favs, setFavs] = useState(
    //     () =>  {
    //          const localData = localStorage.getItem('favs');
    //          return localData ? JSON.parse(localData) : [];
    //     }
    // );   

    const addFav = (img, name, model, price, slug, id) => {
        return Promise.resolve(setFavs([
            ...favs, { img, name, model, price, slug, id }
        ])
        )
    }

    const removeFav = (id) => {
        return Promise.resolve(setFavs(favs.filter(fav => fav.id !== id)))
    }

    useEffect(() => {
        localStorage.setItem('favs', JSON.stringify(favs));
    }, [favs])

    return (
        <FavContext.Provider value={{ favs, addFav, removeFav, added, setAdded }}>
            {props.children}
        </FavContext.Provider>
    )
}

export default FavContextProvider;





